//@import "../node_modules/bootstrap/scss/bootstrap";

body {
    background-color: #1f1f1f;
    font-family: 'Libre Franklin', sans-serif !important;
}

.sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #fff;
    padding: 6px 12px;
    font-family: monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
}

span.dot {
    height: 10px;
    width: 10px;
    margin-top: 3px;
    border-color: white;
    border: solid;
    border-width: 1px;
    border-radius: 50%;
    display: inline-block;
}